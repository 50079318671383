<template>
    <div>
        <div style="position: relative;" :id="ddlWrapperDivID">
            <v-autocomplete 
                @input="handleInput"
                hide-details
                hint="Type a name and use Arrows to select from list..."
                v-model="employee"
                :items="filteredPartnerList"
                :menu-props="{maxHeight: '200', offsetY: true, offsetOverflow: true, top: OpenToTop}"
                item-text="partnerName"
                item-value="partnerId"
                label="Enter your Employer"
                attach="#PetEmployer-list-container "
                :search-input.sync="search"
                @change="employeerSelected"
                @keydown="handleKeyDown"
                @blur="handleBlur"
                clearable
                hide-selected
                id="companySelect"
                aria-expanded="false"
                aria-autocomplete="list"
                ref="companySelectAutocomplete"
                role="combobox"
                aria-describedby="petEmloyerSelectDescription"
                hide-no-data
                :rules="rules"
                class="mb-6 no-drop" 
                :loading="loading"
                :disabled="loading"
            >
                <template v-slot:append>
                    <v-tooltip color="white" bottom max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                                <v-icon class="ml-2 icon-tooltip" color="primary" v-bind="attrs" v-on="on"  small>mdi-help</v-icon>
                        </template>
                        <span>You can save as much as <b>10%</b> through your employee benefits program. <a aria-label="Go to Footnote 3 on Discounts." id="anchorFootnote3" ref="anchorFootnote3" @click="goToElementAndFocus('footnote-3', 'footnote3')"><sup>[3]</sup></a> </span>                    
                    </v-tooltip> 
                </template>
                <template v-slot:message="{ message,key }">
                    <span id="petEmloyerSelectDescription">{{message}}</span>
                </template>  
            </v-autocomplete>
        </div>
    </div>
</template>
<script>
    import {Fragment} from 'vue-fragment';
    import Swal from 'sweetalert2';
    import moment from 'moment';
    import partner from './js/partner.js';
    export default {
        name: 'UserEmployeer',
        mixins: [partner],
        components: {Fragment},
        data: () => ({
            loading: false
        }),
        computed: {
            partner: function () {
                return this.$store.getters.partner;
            },
            payOption: function () {
                return this.$store.getters.payOption;
            },
        },
        async created() {
            this.loading = true;
            this.$store.dispatch("getListOfPartners").then(res => {
                var partnerList = [];
                res.forEach((partner) => {
                    if (partner.partnerAliases !== null) {
                        partner.partnerAliases.forEach((alias) => {
                            var aliasPartner = {
                                "partnerId": partner.partnerId,
                                "partnerName": alias
                            };
                            partnerList.push(aliasPartner);
                        });
                    }
                    partnerList.push(partner);
                });

                // SORT LIST BY NAME TO SHOW IN PROPER ORDER
                partnerList.sort(function (a, b) {
                    var textA = a.partnerName.toUpperCase();
                    var textB = b.partnerName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                this.partnerList = partnerList;

                // Remove Required Field if enrollment code is from 
                // Ads Lead
                if (this.enrollmentCode === "99-99-03-1111") {
                    this.rules = [];
                }

                this.$nextTick(()=>{
                    if(this.partner.id) {
                        this.search = this.partner.name;
                        this.employee = this.partner.id;
                        this.$emit("setValidEmployer", this.employee);
                    } 
                });
                this.loading = false;
            });
            this.loading = false;
        },
        methods: {
            async getEmployerInfo(partnerId) {
                var self = this;
                let futureEnrollmentDate = false;

                if(partnerId) {
                    var response = await self.$store.dispatch("getEmployerInfoByPartnerId", partnerId);

                    if (response.enrollmentDate !== null && response.enrollmentDate !== "") {
                        if (moment(response.enrollmentDate).isAfter(moment())) {
                            futureEnrollmentDate = true;
                        }
                    }

                    if (futureEnrollmentDate) {
                        Swal.fire({
                            title: 'Future Enrollment Date',
                            text: "Your group's enrollment period is not yet open. Please contact us at 1-800-GETMET8 to review coverage options for your pet.",
                            icon: 'info',
                            confirmButtonText: 'Okay'
                        }).then(() => {
                            sessionStorage.clear();
                            this.$store.reset();
                            window.location.replace("https://www.metlifepetinsurance.com/pet-insurance/");
                        });
                    }
                }

                return;
            },
            async employeerSelected(value) {
                this.valueIsChosen = true;
                await this.getEmployerInfo(value);
                this.declareDropdownCollapseState();
                this.focus();
                this.$emit("setValidEmployer", this.employee);
            },
        }
    }
</script>
<style>
.no-drop .v-input__icon.v-input__icon--append{
    display: none;
}
</style>