<template>
    <div class="section-wrap">
        <DotLoader :loading="loading" :loaderText="loaderText" :onLoadingComplete="handleLoaderComplete" />

        <StepHeader ref="stepHeader" :stepDetails=stepHeaderConfig></StepHeader>

        <div class="form-inner" v-if="paymentRequestList == null">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-card elevation="2" class="rounded-tr-0 rounded-tl-0 spacing-playground v-card-form">
                                <v-row justify="center">
                                    <v-col cols="12" md="6">
                                        <v-card-text>
                                            <!-- <SelectPaymentMethod></SelectPaymentMethod> -->
                                            <PaymentInfo @setPaymentRequest="setPaymentRequest" @disableNextParentBtn="disabledBtn" ref="paymentInfo"></PaymentInfo>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="form-inner" v-else>
            <PaymentConfirmation 
                ref="paymentConfirmation"
                @setLoader="setLoader"
            >
            </PaymentConfirmation>
        </div>

        <v-footer ref="controlZoomLevel" app color="white" elevation="2">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="auto">
                    <v-btn aria-label="Go Back Previous Step" @click="goBack" > 
                        {{ previousButtonText }} 
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" @click="goNext" :disabled="loading || btnDisabled">
                        {{ nextButtonText }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>

<script>
    import PaymentInfo from "../../components/global/PaymentInfo.vue";
    import PaymentConfirmation from "../../components/global/PaymentConfirmation.vue";
    import SelectPaymentMethod from "../../components/global/SelectPaymentMethod.vue";
    import StepHeader from '../../components/shared/StepHeader.vue';
    import DotLoader from '../../components/shared/DotLoader.vue';

    export default {
        name: "QFPayment",
        components: { PaymentInfo, PaymentConfirmation, SelectPaymentMethod, StepHeader, DotLoader },
        data() {
            return {
                paymentRequestList: null,
                previousButtonText: "Back",
                loading: false,
                loaderText: "",
                onLoderComplete: () => {},
                btnDisabled: false
            }
        },
        computed: {
            isFamilyPlan: function () {
                return this.$store.getters.isFamilyPlan;
            },
            pets: function () {
                return this.$store.getters.pets;
            },
            partner: function () {
                return this.$store.getters.partner;
            },
            contact: function () {
                return this.$store.getters.checkoutContact;
            },
            nextButtonText: function () {
                return this.paymentRequestList == null ? "Confirm payment" : "Purchase";
            },
            isMetGen: function(){
                return this.$store.getters.isMetGen;
            },
            payOption: function(){
                return this.$store.getters.payOption;
            },
            employeeToken: function(){
                return this.$store.getters.employeeToken;
            },
            IAICPayOptionSetUp: function(){
                return this.$store.getters.getIAICPayOptionSetUp;
            }
        },
        created() {
            this.stepHeaderConfig = {
                stepHeading: "Purchase",
                currentStep: 4,
                totalSteps: 5
            };
        },
        mounted() {
             this.focusHeader();

            window.addEventListener("resize",(e) => {
            let zoomLevel = Math.round(((window.outerWidth) / window.innerWidth) * 100 + 1);
            if(zoomLevel >= 400) {
                    setTimeout(() => {
                        this.handleZoomControl();
                    }, 100)
                } else {
                    this.retainBackZoomControlClasses();
                }
            })
            if(this.employeeToken && !this.isMetGen && this.partner.billingOptions === 'both') {
                if(!this.IAICPayOptionSetUp) {
                    this.$store.commit("setPayOption", 'both');
                    this.$store.commit("setIAICPayOptionSetUp", true);
                }
            }
            this.$store.dispatch("createOrUpdateLeadCustomerInfo")
                .then(() => {
                    this.$store.dispatch("createOrUpdateLeadCustomerActivity")
                });

            window.liQ = window.liQ || [];

            // Optimization: removing QuantumServe.com Call 
            // window._qevents = window._qevents || [];
            // (function() {
            //     var elem = document.createElement('script');
            //     elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
            //     elem.async = true;
            //     elem.type = "text/javascript";
            //     var scpt = document.getElementsByTagName('script')[0];
            //     scpt.parentNode.insertBefore(elem, scpt);
            // })();
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.PageView",
            //     "event": "refresh",
            //     "uid": this.$store.getters.owner.email
            // });

            // window._qevents = window._qevents || [];
            // window._qevents.push({
            //     "qacct": "p-800TOflix8dOQ",
            //     "labels": "_fp.event.Pet Insurance Quote: Step 4",
            //     "event": "refresh"
            // });
        },
        methods: {
            setPaymentRequest: function (request) {
                this.paymentRequestList = request;
            },
            focusHeader() {
                this.$nextTick(() => {
                    this.$refs.stepHeader.focus();
                }); 
            },
            retainBackZoomControlClasses(){
                if(this.$refs.controlZoomLevel){
                    let classesToAdd = this.$refs.controlZoomLevel.$el;
                    classesToAdd.classList.add("v-footer");
                    classesToAdd.classList.add("v-footer--fixed");
                }
            },
            handleZoomControl(){
                if(this.$refs.controlZoomLevel) {
                    let classesToremove = this.$refs.controlZoomLevel.$el;
                    classesToremove.classList.remove("v-footer");
                    classesToremove.classList.remove("v-footer--fixed");     
                }
            },
            goBack() {
                if (this.paymentRequestList == null) {
                    this.$store.commit("setCurrentPage","Contact Info");
                    this.$store.commit("clearPolicies");
                    this.redirectToView("billing");
                } else {
                    this.paymentRequestList = null;
                    this.$store.commit("setPaymentRequestList", null);

                    this.$store.commit("setCurrentPage","Contact Info");
                    this.$store.commit("clearPolicies");
                    this.redirectToView("billing");
                }
            },
            async goNext() {
                if (this.paymentRequestList == null) {
                    this.onLoderComplete=this.focusHeader;
                    this.$refs.paymentInfo.generateSubmissionRequest(null);
                } else {
                    await this.setLoader(true, "Submitting payment, Please wait");

                    if(await this.$refs.paymentConfirmation.submitPayment()) {
                        this.$store.commit("setCurrentPage", "Order Confirmation");

                        this.$store.commit('setStepComplete', 'payment');
                        this.$store.commit('setStepActive', 'summary');

                        this.redirectToView("summary");
                    }
                }
            },
            handleLoaderComplete() {
                if(this.onLoderComplete) {
                    this.onLoderComplete();

                    this.onLoderComplete = () => {};
                }
            },
            async setLoader(loading, loaderText, loaderCompleteCallback) {
                this.loading = loading;
                this.loaderText = loaderText;
                
                if(loaderCompleteCallback) {
                    this.onLoderComplete = null;
                    this.onLoderComplete = () => { 
                        loaderCompleteCallback();
                    }
                }
            },
            redirectToView(step) {
                this.routerPush(step);
            },
            disabledBtn(disable) {
                this.btnDisabled = disable;
            }
        },
        
        updated(){
            window.addEventListener("resize",(e) => {
            let zoomLevel = Math.round(((window.outerWidth) / window.innerWidth) * 100 + 1);

            if(zoomLevel >= 400) {
                    setTimeout(() => {
                        this.handleZoomControl();
                    }, 100);
                } else{
                    this.retainBackZoomControlClasses();
                }
            });
        }
    }
</script>