<template>
    
    <v-card class="px-0" flat v-show="partner.id">

        <v-card-text class="px-0" flat v-if="!hasProviderData()">
            <v-divider></v-divider>
            <span class="d-flex flex-row align-start">
                <v-checkbox hide-details v-model="editProviderForm" :label="listPets( 'or', true)  + ' currently have Insurance with another pet insurance provider?'">
                    <template v-slot:append>
                        <v-tooltip bottom max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="ml-2 icon-tooltip" color="primary" v-bind="attrs" v-on="on"  x-small>mdi-help</v-icon>
                                    </template>
                                    <span>By switching over to MetLife, 
                                            <span v-for="(pet, index) in pets" :key="pet.name">
                                            <span v-if="index === pets.length - 1  && pets.length > 1"> and </span>{{ pet.name }}<span v-if="index !== pets.length - 1 && index !== pets.length - 2 && pets.length > 2 ">, </span>
                                        </span>
                                        <span> may be eligible for coverage of pre-existing conditions covered by your current pet insurance provider.</span>
                                </span>
                        </v-tooltip>
                    </template>
                </v-checkbox>
                
            </span>
        </v-card-text>
        <v-card-text class="d-flex flex-row justify-start align-center px-0" v-if="hasProviderData() || showInsuranceProviderSearch">
            <div>
                <h2 class='mt-9 mb-8'>Current Pet Insurance Provider </h2>
                <span>If applicable, please add your currrent pet insurance provider below.</span>
            </div>
        </v-card-text>


        <v-card-text v-if="hasProviderData()" class="px-0 pt-0"> <!--has data for insurance-->
            <div v-if="haveSameProviderData()">
                <span v-for="(pet, index) in PetsWithProvider">
                    <span v-if="index === PetsWithProvider.length - 1 && PetsWithProvider.length > 1"> and </span><span v-if="pet.provider" class="text-subtitle-2 font-weight-bold">{{ pet.name }}</span><span v-if="index !== PetsWithProvider.length - 1 && index !== PetsWithProvider.length - 2 && PetsWithProvider.length > 2">, </span>            
                </span> : 
                <span  class="text-subtitle-2 font-weight-black" v-html="getUniqueProvider()"></span>
            </div>
            <div v-else>
                <div v-for="pet in PetsWithProvider">
                    <span v-if="pet.provider" class="d-block text-subtitle-2 font-weight-black">{{ pet.name }} - {{ pet.provider }}</span>
                </div>
            </div>                
        </v-card-text>
        <v-btn @click="showProviderSearch()" color="primary" outlined v-if="hasProviderData() && !showInsuranceProviderSearch"> 
                <span v-if="hasProviderData()">
                    <v-icon>mdi-pencil</v-icon> Edit Pet Insurance Provider
                </span>
                <span v-else>
                    <v-icon>mdi-plus</v-icon> Add Pet Insurance Provider
                </span>
        </v-btn>

        <v-card-text class="px-0 py-0" v-if="showInsuranceProviderSearch">
            <div v-if="!allInsuranceProvidersAreEqual">
                <template v-for="(pet, index) in pets">
                    <v-autocomplete 
                        hide-details
                        hint="Type a name and use Arrows to select from list..."
                        v-model="selectedInsuranceProviders[index]"
                        :items="filteredInsuranceProviders[index]" 
                        :menu-props="{maxHeight: '200', offsetY: true, offsetOverflow: true, top: OpenToTop}"
                        item-text="name" 
                        item-value="id"
                        :search-input.sync="searches[index]"
                        :search-input="searchInput"
                        @change="petProviderSelected($event, pet, index)"
                        @keydown="handleKeyDown"
                        @blur="handleBlur"
                        clearable
                        hide-selected
                        :id="'providerSelect' + index"
                        aria-expanded="false"
                        aria-autocomplete="list"
                        :ref="'providerSelectAutocomplete' + index"
                        role="combobox"
                        hide-no-data
                        class="mb-6"
                        :loading="loading"
                        :disabled="loading"
                        :key="index"
                    >
                        <template v-slot:prepend-inner>
                            <span class="d-flex align-start flex-row mt-1">Enter <b class="mx-1"> {{ pet.name }}'s </b> Provider:  </span>
                        </template>
                    </v-autocomplete>
                </template>      
                <v-checkbox v-model="allInsuranceProvidersAreEqual" v-if="pets.length > 1" label="All my pet have the same insurance provider."></v-checkbox>         
                <v-btn @click="confirmPetProvider">Confirm Insurance Provider Information</v-btn>
            </div>
            <div v-else>
                <v-autocomplete
                    hide-details
                    hint="Type a name and use Arrows to select from list..."
                    v-model="selectedInsuranceProvider"
                    :items="filteredInsuranceProvider" 
                    :menu-props="{maxHeight: '200', offsetY: true, offsetOverflow: true, top: OpenToTop}"
                    item-text="name" 
                    item-value="id"
                    :search-input.sync="search"
                    :search-input="searchInput"
                    @change="providerSelected"
                    @keydown="handleKeyDown"
                    @blur="handleBlur"
                    clearable
                    hide-selected
                    id="providerSelect"
                    aria-expanded="false"
                    aria-autocomplete="list"
                    ref="providerSelectAutocomplete"
                    role="combobox"
                    hide-no-data
                    class="mb-6" 
                    :loading="loading"
                    :disabled="loading"
                >
                    <template v-slot:prepend-inner>
                        <div class="d-flex align-start flex-row mt-1">
                            <span>
                                Enter <b>  {{ listPets( 'and', false) }}'s  </b> Provider: 
                            </span>
                            <!-- <span v-for="pet in pets" :key="pet.name" class="mr-1">{{ pet.name }}</span>  <v-divider class="mx-1" vertical></v-divider> -->
                        </div>
                    </template>
                </v-autocomplete>
                <v-checkbox v-model="allInsuranceProvidersAreEqual" v-if="pets.length > 1" label="All my pet have the same insurance provider."></v-checkbox>         
                <v-btn @click="confirmProvider">Confirm Insurance Provider Information</v-btn>
            </div>      
            
        </v-card-text> 
    </v-card>
</template>
<script>
    import Fuse from 'fuse.js';
    export default {
        data() {
            return {
                menuMaxHeight: 200,
                ddlWrapperDivID: "PetEmployer-list-container",
                OpenToTop: false,

                showInsuranceProviderSearch: false,
                allInsuranceProvidersAreEqual: false,

                searchInput: "",
                insuranceProviders: [],
                originalProviders: [],
                
                selectedInsuranceProvider: "",
                selectedInsuranceProviders: [],
                
                filteredInsuranceProvider: [],
                filteredInsuranceProviders: [],

                search: null,
                hasInsuranceWithOtherProvider: false,
                searches: [],
                previousSearches: [],

                editProviderForm : false,
                loading: false
            }
        },
        computed: {
            partner: function () {
                return this.$store.getters.partner;
            },
            pets: function () {
                return this.$store.getters.pets;
            },
            PetsWithProvider: function() {
                const validPets = this.pets.filter(pet => (
                    pet.providerId !== undefined && pet.providerId !== null &&
                    pet.provider !== undefined && pet.provider !== null 
                ));
                if(validPets.length === 0){
                    return null;
                }
                return validPets;
            },
        },
        watch: {
            pets: {
                handler(newPets) {
                    if(newPets && newPets.length > 0) {
                        this.initializeSearchesArray();
                    }
                },
                immediate: true,
            },
            search: function (val) {
                if (val != null && val != undefined) {
                    this.filterFirstResultFound(null, val);
                }
            },
            searches: { 
                handler: function (newSearches, oldSearches) { 
                    for(let index = 0; index < newSearches.length; index++) {
                        if(newSearches[index] !== this.previousSearches[index]) {
                            this.filterFirstResultFound(index, newSearches[index])
                            this.previousSearches[index] = newSearches[index];
                        }
                    } 
                }, deep: true, 
            },
            editProviderForm: function(newValue){
                if(newValue === true){
                    this.showProviderSearch();
                }
                else{
                    this.showInsuranceProviderSearch = false;
                }
            }
        },
        async created() {
            if (this.partner.id) {
                this.insuranceProviders = await this.$store.dispatch("getProviders");
                this.originalProviders = [...this.insuranceProviders];
            } 

            this.selectedInsuranceProviders = Array(this.pets.length).fill('');
            this.filteredInsuranceProviders = Array(this.pets.length).fill([]);
            
        },
        methods: {
            initializeSearchesArray() {
                this.searches =  new Array(this.pets.length).fill('');
            },
            initA11yForARIA12Combo(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Initially try to adjust roles of wrapper div and input inside it
                        try{
                            let comboRoleDiv = comboContainer.querySelector("[role='combobox']");
                            let listId = comboRoleDiv.getAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("role");
                            comboRoleDiv.removeAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("aria-haspopup");
                            comboRoleDiv.removeAttribute("aria-expanded");
                            if(listId !== null) input.setAttribute("aria-controls", listId );
                        } catch(e) {}
                    }
                });
            },
            labelExpandIconOnMount(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the expand icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner div:has(>i)");
                            button.setAttribute("aria-label", "Expand list for " + labelText);
                            button.setAttribute("role", "button");
                            button.setAttribute("aria-expanded", "false");
                            button.addEventListener("click", this.handleExpandIconClick);
                        } catch(e) {}
                    }
                });
            },
            handleKeyDown(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");

                if(input.getAttribute("aria-expanded") === "true" && (e.key === "Escape" || e.key === "Tab" || (e.key === "Enter" && input.getAttribute("aria-activedescendant") !== ""))){
                    this.setAsCollapsed(input, expandIcon);
                }

                if(input.getAttribute("aria-expanded") === "true" && e.key === "Backspace" && /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
                    this.removeTabindexFromListBox(input);
                }
            },
            setAsExpanded(input, expandIcon, index) {
                // ipad and ios fix for inverse state;
                var iOS= /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream;

                if(iOS){
                    this.removeTabindexFromListBox(input);
                }

                // this.OpenToTop = this.openDirectionCheckup(this.ddlWrapperDivID, this.menuMaxHeight);
                let state = "true";
                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
                this.assignClickToSelectedOption(input, expandIcon, index);
            },
            setAsCollapsed(input, expandIcon) {
                let state = "false";

                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
            },
            handleBlur(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");

                this.setAsCollapsed(input, expandIcon);
            },
            removeTabindexFromListBox(input){
                this.$nextTick(() => {
                    setTimeout(() => {
                        let listBoxContainer =  document.getElementById(input.getAttribute("aria-controls"));
                        listBoxContainer.removeAttribute("tabindex");

                        let listBoxOptions = listBoxContainer.querySelectorAll("div[role=option]");
                        listBoxOptions.forEach(optionElement=> {
                            optionElement.removeAttribute("tabindex")
                        });
                    },100);
                })
            },
            assignClickToSelectedOption(input, expandIcon) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        let selectedListContainer = document.getElementById(input.getAttribute("aria-controls"));
                    
                        if(selectedListContainer) {
                            let selectedListItem = selectedListContainer.querySelector("div[role=option][aria-selected=true]");

                            let itemClickHandler = () => {
                                this.setAsCollapsed(input, expandIcon);
                                this.focus(null);//focus lost fix for iphone and ipad
                                selectedListItem.removeEventListener("click", itemClickHandler);
                            };
                            try{
                                selectedListItem.addEventListener("click", itemClickHandler);
                            } catch {};
                        }
                        
                    }, 100);
                });
            },
            handleExpandOnClick(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                input.setAttribute("aria-expanded","true");
                expandIcon.setAttribute("aria-expanded","true");
            },
            handleExpandIconClick(e) {
                let expandIcon = e.currentTarget;
                let input = expandIcon.parentElement.parentElement.querySelector("input[type=text]");
                if (expandIcon.getAttribute("aria-expanded") === "false") this.setAsExpanded(input, expandIcon);
                else this.setAsCollapsed(input, expandIcon);
            },
            labelClearIconWhenAvailable(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the clear icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {}
                    }
                });
            },
            declareDropdownCollapseState(index) {
                this.$nextTick(() => {
                    // if(index === null) {
                    //     let input = this.$refs['providerSelectAutocomplete'].$el.querySelector("input[type='text']");
                    //     let expandIcon = this.$refs['providerSelectAutocomplete'].$el.querySelector(".v-input__append-inner div:has(>i)");
                    //     this.setAsCollapsed(input, expandIcon);
                    // } else {
                    //     let input = this.$refs['providerSelectAutocomplete' + index].$el.querySelector("input[type='text']");
                    //     let expandIcon = this.$refs['providerSelectAutocomplete' + index].$el.querySelector(".v-input__append-inner div:has(>i)");
                    //     this.setAsCollapsed(input, expandIcon);
                    // }
                });
            },
            declareDropdownExpandState(index){
                this.$nextTick(() => {
                    // if(index ===  null) {
                    //     let input = this.$refs.providerSelectAutocomplete.$el.querySelector("input[type='text']");
                    //     let expandIcon = this.$refs.providerSelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");

                    //     this.setAsExpanded(input, expandIcon);
                    // } else {
                    //     let input = this.$refs['providerSelectAutocomplete' + index].$el.querySelector("input[type='text']");
                    //     let expandIcon = this.$refs['providerSelectAutocomplete' + index].$el.querySelector(".v-input__append-inner div:has(>i)");

                    //     this.setAsExpanded(input, expandIcon);
                    // }
                });
            },
            focus(index){
                // if(index ===  null) {
                //     this.$refs['providerSelectAutocomplete'].$el.querySelector("input[type=text]").focus();
                // } else {
                //     this.$refs['providerSelectAutocomplete' + index].$el.querySelector("input[type=text]").focus();
                // }
            },
            hasProviderData(){
                return this.pets.some(pet =>  (
                    pet.providerId !== undefined && pet.providerId !== null &&
                    pet.provider !== undefined && pet.provider !== null 
                ));
            },
            haveSameProviderData(){
                //create a new array for only pets with valid providerID and provider
                const validPets = this.pets.filter(pet => (
                    pet.hasOwnProperty('providerId') && pet.providerId !== undefined && pet.providerId !== null &&
                    pet.hasOwnProperty('provider') && pet.provider !== undefined && pet.provider !== null 
                ));
                if(validPets.length === 0){
                    return false;
                }
                //find first pet with provider
                const firstPetWithProvider = validPets[0];
    
                return validPets.every(pet => (
                    pet.providerId === firstPetWithProvider.providerId &&
                    pet.provider === firstPetWithProvider.provider
                ));
            },
            allPetsHaveSameProviderData(){
                //create a new array for only pets with valid providerID and provider
                const validPets = this.pets.filter(pet => (
                    pet.hasOwnProperty('providerId') && pet.providerId !== undefined && pet.providerId !== null &&
                    pet.hasOwnProperty('provider') && pet.provider !== undefined && pet.provider !== null 
                ));
                if(this.pets.length !== validPets.length){
                    return false;
                }
                if(validPets.length === 0){
                    return false;
                }
                //find first pet with provider
                const firstPetWithProvider = validPets[0];
    
                return validPets.every(pet => (
                    pet.providerId === firstPetWithProvider.providerId &&
                    pet.provider === firstPetWithProvider.provider
                ));
            },
            getUniqueProvider(){
                const firstPetWithProvider = this.pets.find( pet => (
                    ('providerId' in pet && 'provider' in pet) &&
                    pet.providerId !== null &&
                    pet.provider !== null
                ));
                if(!firstPetWithProvider){
                    return null;
                }
                return firstPetWithProvider.provider;                
            },
            handleSearchInput(searchText, filteredList, index) {
                let i;
                for(i=0; i<filteredList.length; i++){
                    if(filteredList[i].name.toUpperCase().includes(searchText.toUpperCase())) {
                        break;
                    }
                }
                if(i<filteredList.length && (this.valueIsChosen === undefined || this.valueIsChosen=== false )) {
                    this.declareDropdownExpandState(index);     
                } else {
                    this.valueIsChosen=false;
                    this.declareDropdownCollapseState(index);
                }
            },
            filterFirstResultFound(index, searchText) {
                if(searchText) {
                    var fuseSearch = new Fuse(this.insuranceProviders,{
                        keys: [
                            "name"
                        ]
                    });
                    var results = fuseSearch.search(searchText, {limit: 5});
                    
                    var filteredList = [];
                    results.forEach(result => {
                        filteredList.push(result.item);  
                    });
                    
                    this.handleSearchInput(searchText, filteredList, index);
                    index !== null
                        ? this.filteredInsuranceProviders[index] = filteredList
                        : this.filteredInsuranceProvider = filteredList;
                }
            },
            async petProviderSelected(value, pet, index) {
                this.valueIsChosen = true;
                this.declareDropdownCollapseState(index);
                this.focus(index);
            },
            async providerSelected(value) {
                this.valueIsChosen = true;
                this.declareDropdownCollapseState(null);
                this.focus(null);
            },
            existProviderById(id) {
                return this.insuranceProviders.some(provider => provider.id === id);
            },
            existProviderByName(name) {
                return this.insuranceProviders.some(provider => provider.name === name);
            },
            existProviderByIdAndByName(id, name) {
                return this.originalProviders.some(provider => provider.id === id && provider.name === name);
            },
            saveProvider(onAllPets, id, name, petIndex) {
                if(onAllPets) {
                    for(let index = 0; index < this.pets.length; index++) {
                        this.$store.commit("saveProviderId", { petIndex: index, providerId: id, previousCoverage: name });
                    }
                } else {
                    this.$store.commit("saveProviderId", { petIndex: petIndex, providerId: id, previousCoverage: name });
                }
            },
            closeProviderForm() {
                this.editProviderForm = false;
                this.showInsuranceProviderSearch = false;
            },
            confirmPetProvider() {
                for(let index = 0; index < this.pets.length; index++) {
                    if(this.existProviderByIdAndByName(this.selectedInsuranceProviders[index], this.searches[index])) {
                        const provider = this.insuranceProviders.find(provider => provider.id === this.selectedInsuranceProviders[index]); 
                        this.saveProvider(false, provider.id, provider.name, index); 
                    } else {
                        if(this.searches[index]) {
                            this.saveProvider(false, 11, this.searches[index], index); 

                            if(!this.existProviderByName(this.searches[index])) {
                                this.insuranceProviders.push({ id: 11, name: this.searches[index] })
                            }
                        } else {
                            this.saveProvider(false, null, null, index); 
                            this.searches[index] = "";
                            this.selectedInsuranceProviders[index] = "";
                            this.search = null;
                            this.selectedInsuranceProvider = "";
                        }
                    }
                }

                this.closeProviderForm();
            },
            confirmProvider() {
                if(this.existProviderByIdAndByName(this.selectedInsuranceProvider, this.search)) {
                    const provider = this.insuranceProviders.find(provider => provider.id === this.selectedInsuranceProvider);
                    this.saveProvider(true, provider.id, provider.name);
                } else {
                    if(this.search) {
                        this.saveProvider(true, 11, this.search);
                          
                        if(!this.existProviderByName(this.search)) {
                            this.insuranceProviders.push({ id: 11, name: this.search })
                        }
                    } else {
                        this.saveProvider(true, null, null);
                        this.search = null;
                        this.selectedInsuranceProvider = "";
                        for(let index = 0; index < this.pets.length; index++) {
                            this.searches[index] = "";
                            this.selectedInsuranceProviders[index] = "";
                        }
                    }
                }
                
                this.closeProviderForm();
            },
            async showProviderSearch() {
                this.showInsuranceProviderSearch = !this.showInsuranceProviderSearch;
                this.allInsuranceProvidersAreEqual = this.allPetsHaveSameProviderData();

                if(this.showInsuranceProviderSearch) {
                    if(!this.allInsuranceProvidersAreEqual) {
                        this.loading = true;
                        for(let index = 0; index < this.pets.length; index++) {
                            if(this.pets[index].providerId) {
                                if(!this.existProviderByName(this.pets[index].provider)) {
                                    this.insuranceProviders.push({ id: 11, name: this.pets[index].provider })
                                }
                                this.$nextTick(()=>{ 
                                    setTimeout(() => {
                                        this.$set(this.searches, index, this.pets[index].provider)
                                        this.selectedInsuranceProviders[index] = this.pets[index].providerId;
                                        this.loading = false;
                                    }, 700);
                                    
                                });
                            }
                        }
                    } else {
                        this.loading = true;
                         
                        if(!this.existProviderByName(this.pets[0].provider)) {
                            this.insuranceProviders.push({ id: 11, name: this.pets[0].provider })
                        }

                        this.$nextTick(()=>{
                            setTimeout(() => {
                                this.search = this.pets[0].provider;
                                this.selectedInsuranceProvider = this.pets[0].providerId;
                                this.loading = false;
                            }, 500);
                        });
                    }
                }
                this.loading = false;
            },
            listPets( string, Dodo ){
                const names = this.pets.map(pet => pet.name);
                var plural = this.pets.length > 1 ? 'Do ': 'Does ';
                if(!Dodo){
                    plural = "";
                }
                if( names.length === 0){
                    return '';
                }
                else if( names.length === 1){
                    return plural + " " + names[0];
                }
                else{
                    const lastItem = names.pop();
                    const joinedNames  = names.join(', ');
                    return plural + " " + joinedNames + " "+  string + " " + lastItem;       
                }
            }
        }
    }
</script>