
<template>
    <div v-if="payOption == 'both'">
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card-text>
                    <v-select v-model="payOption"
                              :items="paymentOptions"
                              label="Please select your payment option"
                              item-text="label"
                              item-value="value">
                    </v-select>

                    <span>Changing payment methods will impact the final payment structure.</span>
                </v-card-text>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "QFCheckoutSelectPaymentMethod",
        data() {
            return {
                paymentOptions: [
                    { label: "Payroll Deduction", value: "payrollDeduct" },
                    { label: "Credit or Debit Card", value: "creditCard" }
                ]
            }
        },
        computed: {
            payOption: {
                get() {
                    return this.$store.getters.payOption;
                },
                set(value) {
                    this.$store.commit("setPayOption", value);
                    // CALCULATE ACCIDENT AND ILLNESS DATES
                    this.$store.dispatch("getDate");
                    this.$store.dispatch("getDatePlus14");
                }
            }
        }
    }
</script>