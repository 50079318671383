<template>
    <div>
        <v-container>
            <div v-if="isQuoteLoaded">
                <noscript v-if="submittingPayment">
                    <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petqu0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
                </noscript>
                <noscript v-if="submittingPayment">
                    <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=189678795578711&ev=PetQuoteSite_AboutMyPet&noscript=1"/>
                </noscript>
                <v-row class="d-flex flex-wrap-reverse">
                    <v-col cols="12" md="8" v-if="!isFamilyPlan">
                        <v-card class="v-card-agreement mb-3" elevation="2" v-for="(plan,index) in selectedPlans" :key="plan.planRate" role="region" :aria-label="'Policy for'+petInfo[index].name">
                            <v-card-title :id="'planConfirmation-'+index">
                                <v-row class="d-flex align-center justify-space-between">
                                    <v-col cols="auto" class="flex-grow-1 d-flex py-1">
                                        <PetView :aria-label="petInfo[index].name + 'Policy.'" :petDetails="petInfo[index]" :isCurrent="false" :showDetails="true" :isEditable="false"></PetView>
             
                                    </v-col>
                                    <v-col aria-live="polite" cols="auto" class="d-flex flex-column py-1">
                                        <v-icon large :class=" agreedPolicies.includes(petInfo[index].id) ? 'secondary--text  text--darken-2' : 'ml_darkergray--text' ">
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <span v-if=" agreedPolicies.includes(petInfo[index].id)" class="caption secondary--text  text--darken-2">
                                            <span class="d-sr-only">As you have agreed the terms for "{{petInfo[index].name}}" your policy is </span>APPROVED
                                        </span>
                                        <span v-else class="caption ml_darkergray--text"> 
                                            <span class="d-sr-only">As you have not agreed the terms for "{{petInfo[index].name}}" your policy is </span>PENDING
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider role="none"></v-divider>
                            <v-card-text  role="region" aria-label="Policy Details">
                                <v-row class="order-details-grid">
                                    <v-col class="py-1" cols="12" md="12" >
                                        <div class="d-flex justify-space-between">
                                            <b>Your Rate</b>
                                            <span> {{plan.planRate}} </span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Annual Limit</b>
                                            <span> {{ reformatPrice ( plan.annualLimit, true) }}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Reimbursement</b>
                                            <span> {{ plan.reimbursement}}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Deductible</b>
                                            <span> {{ reformatPrice (plan.deductible, true) }}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>{{routineCareLabel}}</b>
                                            <span v-if="plan.routineCare === 'None'">No</span>
                                            <span v-else-if="plan.routineCare === 'Standard Wellness'">Yes</span>
                                            <span v-else> {{ reformatCurrencyString (plan.routineCare, true) }}</span>

                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="12">
                                        <div class="d-flex justify-space-between">
                                            <b>Accident Coverage Begins</b>
                                            <span> {{plan.accidentalCoverageStart}}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="12">
                                        <div class="d-flex justify-space-between">
                                            <b>Illness Coverage Begins</b>
                                            <span> {{plan.illnessCoverageStart}}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider role="none"></v-divider>

                            <v-card-text v-if="payOption === 'creditCard'">
                                <v-row>
                                    <v-col cols="6" md="6" :class="paymentFrequency === 'Monthly' ? 'pb-0':''">
                                        <div class="d-flex flex-column">
                                            <b>Would you like to pay annually and
                                                <span class="primary--text">
                                                    save on installment fees?
                                                </span>
                                            </b>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" md="6" :class="paymentFrequency === 'Monthly' ? 'pb-0':''">
                                        <v-radio-group v-model="paymentFrequency" row class="mt-0" @change="setConfirmPolicyPaymentFrequency($event,index)">
                                            <v-radio :aria-label="'for '+petInfo[index].name+' Would You Like To have Annual Plan ? Yes'" :id="'AnnuallyRadio'+index" tabindex="0" label="Yes" value="Annually"></v-radio>
                                            <v-radio :aria-label="'for '+petInfo[index].name+' Would You Like To have Annual Plan? No'" :id="'MonthlyRadio'+index" tabindex="0" label="No" value="Monthly"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" :class="paymentFrequency === 'Monthly' ? 'pt-0':''">
                                        <span v-if="paymentFrequency === 'Monthly'" class="caption">
                                                I understand that if I elect to pay premiums monthly, there is an installment fee of $3.00 per month.
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions class="checkbox-agreement">
                                <div class="fake_checkbox_wrapper">
                                    <input type="checkbox" class="transparent_checkbox" :id="'agreedPolicies-chk-native-' + index" v-model="agreedPolicies" :aria-label="'Approve ' + petInfo[index].name + ' Policy by Checking this checkbox, you acknowledge you have read and agree to MetLife policies shown ahead.'" :checked="agreedPolicies.includes(petInfo[index].id)?'checked':null" @change="handleInputClick(index)"/>
                                    <v-checkbox v-model="agreedPolicies"
                                                :value="petInfo[index].id"
                                                ref="'checkbox'+index"
                                                :id="'agreedPolicies-chk-' + index"
                                                aria-required="true"
                                                @click="handleChecked(index)"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :aria-labelledby="'chk-box'+index"
                                                :aria-label="'Approve ' + petInfo[index].name + ' Policy by Checking the Agreement Checkbox, you acknowledge you have read and agree to MetLife policies shown ahead.'"
                                                >
                                    </v-checkbox>
                                </div>
                                <div class="body-2" :id="'chk-box'+index">
                                    By checking this box, I have electronically signed and acknowledge that I have read and agree to MetLife’s
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" rel='noopener noreferrer'>Application Agreement</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" rel='noopener noreferrer'>Fraud Warning</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" rel='noopener noreferrer'>Privacy Policy</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/terms-and-conditions/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/terms-and-conditions/')" rel='noopener noreferrer'>Terms and Conditions</a>,
                                    <a v-if="owner.state === 'NJ'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')">Mass Marketing Discount Disclosure</a>
                                    <a v-if="owner.state === 'ME'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')">Disclosure for Maine Residents</a>
                                    and
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/PF-E-Delivery-Notice-Consent-Disclosure-8.21.2018.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/PF-E-Delivery-Notice-Consent-Disclosure-8.21.2018.pdf')" rel='noopener noreferrer'>Electronic Delivery and Consent notice</a>.
                                    <!-- You agree to be billed automatically for your policy on an annual or monthly basis via the payment schedule and method selected. -->
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="8" v-else>
                        <v-card class="v-card-agreement">
                            <v-card-title id="familyPlanConfirmation">
                                <v-row class="d-flex">
                                    <v-col cols="auto" class="d-flex py-1" v-for="pet in petInfo" :key="pet.id">
                                        <PetView :aria-label="pet.name + 'Policy.'" :petDetails="pet" :isCurrent="false" :showDetails="true" :isEditable="false"></PetView>
                                    </v-col>
                                    <v-col aria-live="polite" cols="auto" class="d-flex flex-column py-1 ml-auto">
                                        <v-icon x-large :class="agreedPolicies.includes(petInfo[0].id) ? 'secondary--text  text--darken-2' : 'ml_darkergray--text' ">
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <span v-if="agreedPolicies.includes(petInfo[0].id)" class="caption secondary--text  text--darken-2">
                                            <span class="d-sr-only">As you have agreed the terms for pets your policy is </span>APPROVED
                                        </span>
                                        <span v-else class="caption ml_darkergray--text">
                                            <span class="d-sr-only">As you have not agreed the terms for pets your policy is </span>PENDING</span>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider role="none"></v-divider>
                            <v-card-text tabindex="0" role="region" aria-labelledby="familyPlanConfirmation">
                                <v-row>
                                    <v-col cols="12">
                                        <h2>Family Plan</h2>
                                    </v-col>
                                </v-row>
                                <v-row class="order-details-grid">
                                    <v-col class="py-1" cols="12" md="12">
                                        <div class="d-flex justify-space-between">
                                            <b>Your Rate</b>
                                            <span> {{familyPlan[0].planRate}} </span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Annual Limit</b>
                                            <span> {{ reformatPrice (familyPlan[0].annualLimit , true) }}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Reimbursement</b>
                                            <span> {{familyPlan[0].reimbursement}}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>Deductible</b>
                                            <span> {{ reformatPrice (familyPlan[0].deductible, true) }}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="6">
                                        <div class="d-flex justify-space-between">
                                            <b>{{routineCareLabel}}</b>
                                            <span v-if="familyPlan[0].routineCare === 'None'">No</span>
                                            <span v-else-if="familyPlan[0].routineCare === 'Standard Wellness'"> Yes </span>
                                            <span v-else> {{ reformatCurrencyString (familyPlan[0].routineCare , true) }}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="12">
                                        <div class="d-flex justify-space-between">
                                            <b>Accident Coverage Begins</b>
                                            <span> {{familyPlan[0].accidentalCoverageStart}}</span>
                                        </div>
                                    </v-col>
                                    <v-col class="py-1" cols="12" md="12">
                                        <div class="d-flex justify-space-between">
                                            <b>Illness Coverage Begins</b>
                                            <span> {{familyPlan[0].illnessCoverageStart}}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider role="none"></v-divider>

                            <v-card-text v-if="payOption === 'creditCard'">
                                <v-row>
                                    <v-col cols="6" md="6" :class="paymentFrequency === 'Monthly' ? 'pb-0':''">
                                        <div class="d-flex flex-column">
                                            <b>Would you like to pay annually and
                                                <span class="primary--text">
                                                    save on installment fees?
                                                </span>
                                            </b>
                                        </div>
                                    </v-col>
                                    <v-col cols="6" md="6" :class="paymentFrequency === 'Monthly' ? 'pb-0':''">
                                        <v-radio-group v-model="paymentFrequency" row class="mt-0" @change="setConfirmPolicyPaymentFrequency">
                                            <v-radio aria-label="Would You Like To have Annual Plan for your Pets? Yes" id="AnnuallyRadio" label="Yes" tabindex="0" value="Annually"></v-radio>
                                            <v-radio aria-label="Would You Like To have Annual Plan for your Pets? No" id="MonthlyRadio" label="No" tabindex="0" value="Monthly"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="12" :class="paymentFrequency === 'Monthly' ? 'pt-0':''">
                                        <span v-if="paymentFrequency === 'Monthly'" class="caption">I understand that if I elect to pay premiums monthly, there is an installment fee of $3.00 per month.</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions class="checkbox-agreement">
                                <div class="fake_checkbox_wrapper">
                                    <input type="checkbox" class="transparent_checkbox" :id="'agreedPolicies-chk-native-'+index"  aria-label="Approve Family Policy by Checking this checkbox, you acknowledge you have read and agree to MetLife policies shown ahead." :checked="agreedPolicies.includes(petInfo[index].id)?'checked':null" @change="handleInputClick(0)"/>
                                    <v-checkbox v-model="agreedPolicies"
                                                :value="petInfo[0].id"
                                                id="agreedPolicies-chk-0"
                                                aria-required="true"                                                
                                                @click="handleChecked(0)"
                                                tabindex="-1"
                                                aria-hidden="true"
                                                :aria-labelledby="'chk-box'+index"
                                                aria-label="Approve Family Policy by Checking the Agreement Checkbox, you acknowledge you have read and agree to MetLife policies shown ahead."
                                                >
                                    </v-checkbox>
                                </div>
                                <div class="body-2" :id="'chk-box'+index">
                                    By checking this box, I have electronically signed and acknowledge that I have read and agree to MetLife’s
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/application-agreement/')" rel='noopener noreferrer'>Application Agreement</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/fraud-warning/')" rel='noopener noreferrer'>Fraud Warning</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlife.com/about-us/privacy-policy/online-privacy-policy/')" rel='noopener noreferrer'>Privacy Policy</a>,
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/terms-and-conditions/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/terms-and-conditions/')" rel='noopener noreferrer'>Terms and Conditions</a>,
                                    <a v-if="owner.state === 'NJ'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/mass-marketing-discount-disclosure-notice-new-jersey/')">Mass Marketing Discount Disclosure</a>
                                    <a v-if="owner.state === 'ME'" href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/Maine-Disclosure-Notice.pdf')">Disclosure for Maine Residents</a>
                                    and
                                    <a href="#" v-on:click="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/PF-E-Delivery-Notice-Consent-Disclosure-8.21.2018.pdf')" v-on:keyup.enter="redirectToOtherLink('https://www.metlifepetinsurance.com/content/dam/metlifecom/us/metlifepetinsurance/PF-E-Delivery-Notice-Consent-Disclosure-8.21.2018.pdf')" rel='noopener noreferrer'>Electronic Delivery and Consent notice</a>.
                                    <!-- You agree to be billed automatically for your policy on an annual or monthly basis via the payment schedule and method selected. -->
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col class="totals-column" cols="12" md="4">
                        <v-card elevation="2" class="quote-card mx-auto">
                            <v-card-title primary--text id="breakdownRegionLabel">
                                <div class="text-center" role="heading" aria-level="2">Quote Breakdown</div>
                            </v-card-title>
                            <v-card-text aria-live="polite" aria-atomic="true" role="region" aria-labelledby="breakdownRegionLabel">
                                <div class="quote-breakdown d-flex flex-column">
                                    <v-row no-gutters class="quote-section">
                                        <v-col cols="4" class="quote-text"> Total Premium </v-col>
                                        <v-col cols="8" class="quote-value">
                                            <span class="d-sr-only">Total Premium, </span> {{ reformatPrice(quoteTotals[0].subTotal) }}
                                        </v-col>
                                    </v-row>
                                    <div v-if="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                                        <v-row no-gutters class="quote-section" v-for="(fee, index) in sortedFees(this.policies[0].plan.paymentSchedule.feeTotalBreakdown)" :key="index">
                                            <v-col cols="4" class="quote-text" aria-label="Fees,"> {{ getFeesShortDescription(fee.description) }} </v-col>
                                            <v-col cols="8" class="quote-value">
                                                <span class="d-sr-only">Fees, </span> + {{reformatPrice(fee.amount / 12)}}
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-else>
                                        <v-row no-gutters class="quote-section" v-if="quoteTotals[0].fees !== 0">
                                            <v-col cols="4" class="quote-text" aria-label="Fees,"> {{ getFeesLabel() }} </v-col>
                                            <v-col cols="8" class="quote-value">
                                                <span class="d-sr-only">Fees, </span> + {{reformatPrice(quoteTotals[0].fees)}}
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div v-show="quoteTotals[0].taxes != 0">
                                        <v-row no-gutters class="quote-section">
                                            <v-col cols="4" class="quote-text" aria-label="Taxes,"> Taxes </v-col>
                                            <v-col cols="8" class="quote-value">
                                                <span class="d-sr-only">Taxes, </span> + {{reformatPrice(quoteTotals[0].taxes)}}
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <v-row no-gutters class="quote-section" v-if="quoteTotals[0].discounts !== 0">
                                        <v-col cols="4" class="quote-text" aria-label="Discounts,"> Discounts </v-col>
                                        <v-col cols="8" class="quote-value">
                                            <span class="d-sr-only">Discounts, </span> - {{reformatPrice(quoteTotals[0].discounts)}}
                                        </v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                    <v-row no-gutters class="quote-total">
                                        <v-col cols="4" class="quote-text"> Your Total </v-col>
                                        <v-col cols="8" class="quote-value">
                                            <div class="price-value md">
                                                <span class="currency"> {{  getPriceSplit( quoteTotals[0].total )[0] }} </span>
                                                <span class="units">    {{  getPriceSplit( quoteTotals[0].total )[1] }} </span>
                                                <span class="cents">    {{  getPriceSplit( quoteTotals[0].total )[2] }} </span>
                                            </div>
                                            <div class="frequency">
                                                {{' ' + paymentFrequency}}
                                            </div>
                                        </v-col>

                                    </v-row>

                                </div>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </div>

        </v-container>
    </div>
</template>
<script>
    import PetView from '../shared/pet.vue';

    import paymentConfirmation from './js/paymentConfirmation';

    export default {
        mixins: [paymentConfirmation],
        name: "QFPaymentConfirmation",
        components: {
            PetView
        },
    }
</script>